export const READY = "ready_takephoto";
export const CAMERA_SYNCED = "camera_synced"; // 相机同步完成
export const START = "start_takephoto";
export const LOCAL_UPLOADING = "local_uploading"; // 本地上传中
export const LOCAL_UPLOADED = "local_uploaded"; // 本地上传完成
export const UPLOADING = "uploading"; // 云端上传中
export const UPLOADED = "uploaded"; // 云端上传完成

// READY, 
export const ReadyTake: any[] = [CAMERA_SYNCED, LOCAL_UPLOADED, UPLOADING, UPLOADED];
export const Takephoto: any[] = [CAMERA_SYNCED, LOCAL_UPLOADED, UPLOADED];

export const LocalUploading: any[] = [START, LOCAL_UPLOADING];

export const UploadingWithLocal: any[] = [...LocalUploading, UPLOADING];

export const VideoCheck: any[] = [READY, CAMERA_SYNCED, START, LOCAL_UPLOADING, UPLOADING];