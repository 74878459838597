import { BaseService, Service } from "/@/cool";
@Service("customerP3dLog")
class CustomerP3dLog extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}

		this.permission["makeOrder"] = prefix + "makeOrder";
		this.permission["modelingMake"] = prefix + "modelingMake";
		this.permission["orderPreview"] = prefix + "orderPreview";
		this.permission["downloadAutoModel"] = prefix + "downloadAutoModel";
		this.permission["downloadPrintModel"] = prefix + "downloadPrintModel";
		this.permission["downloadPreviewScenePhoto"] = prefix + "downloadPreviewScenePhoto";
		// this.permission["downloadModel"] = prefix + "downloadModel";
		this.permission["printModel"] = prefix + "printModel";
		this.permission["uploadModel"] = prefix + "uploadModel";
		this.permission["previewModel"] = prefix + "previewModel";
		this.permission["previewScene"] = prefix + "previewScene";
		this.permission["previewAllScene"] = prefix + "previewAllScene";
		this.permission["previewModelScene"] = prefix + "previewModelScene";
		this.permission["assignModelRepairTeam"] = prefix + "assignModelRepairTeam";
		this.permission["assignMultiModelRepairTeam"] = prefix + "assignMultiModelRepairTeam";
		this.permission["customerService"] = prefix + "customerService";
		this.permission["takephotoImageCount"] = prefix + "takephotoImageCount";
		this.permission["takephotoImages"] = prefix + "takephotoImages";
		this.permission["takephotoPreviewImages"] = prefix + "takephotoPreviewImages";
		this.permission["takephotoAllImages"] = prefix + "takephotoAllImages";
		this.permission["notice"] = prefix + "notice";
		// 填写快递单号
		this.permission["express"] = prefix + "express";
		this.permission["retake"] = prefix + "retake";
		this.permission["cancel"] = prefix + "cancel"; // 取消
		this.permission["updateThumb"] = prefix + "updateThumb"; // 取消
		this.permission["editModelWeight"] = prefix + "editModelWeight"; // 取消
		this.permission["excel"] = prefix + "excel"; // 取消
		this.permission["previewAuth"] = prefix + "previewAuth"; // 3d预览授权
		this.permission["gift"] = prefix + "gift"; // 开业测试
	}

	modelingMake(params: any) {
		return this.request({
			url: "/modelingMake",
			method: "POST",
			data: { ...params }
		});
	}

	downloadAutoModel(params: any) {
		return this.request({
			url: "/downloadAutoModel",
			method: "POST",
			data: { ...params }
		});
	}

	downloadPrintModel(params: any) {
		return this.request({
			url: "/downloadPrintModel",
			method: "POST",
			data: { ...params }
		});
	}

	takephotoImageCount(params: any) {
		return this.request({
			url: "/takephotoImageCount",
			method: "POST",
			data: { ...params }
		});
	}

	takephotoImages(params: any) {
		return this.request({
			url: "/takephotoImages",
			method: "POST",
			data: { ...params }
		});
	}

	takephotoPreviewImages(params: any) {
		return this.request({
			url: "/takephotoPreviewImages",
			method: "POST",
			data: { ...params }
		});
	}

	modelTakephotoAllImages(params: any) {
		return this.request({
			url: "/modelTakephotoAllImages",
			method: "POST",
			data: { ...params }
		});
	}

	takephotoAllImages(params: any) {
		return this.request({
			url: "/takephotoAllImages",
			method: "POST",
			data: { ...params }
		});
	}
	notice(params: any) {
		return this.request({
			url: "/notice",
			method: "POST",
			data: { ...params }
		});
	}

	express(params: any) {
		return this.request({
			url: "/express",
			method: "POST",
			data: { ...params }
		});
	}

	retake(params: any) {
		return this.request({
			url: "/retake",
			method: "POST",
			data: { ...params }
		});
	}

	cancel(params: any) {
		return this.request({
			url: "/cancel",
			method: "POST",
			data: { ...params }
		});
	}

	updateThumb(params: any) {
		return this.request({
			url: "/updateThumb",
			method: "POST",
			data: { ...params }
		});
	}

	printModel(params: any) {
		return this.request({
			url: "/printModel",
			method: "POST",
			data: { ...params }
		});
	}
	// 模型指派
	assignModelRepairTeam(params: any) {
		return this.request({
			url: "/assignModelRepairTeam",
			method: "POST",
			data: { ...params }
		});
	}
	// 批量模型指派
	assignMultiModelRepairTeam(params: any) {
		return this.request({
			url: "/assignMultiModelRepairTeam",
			method: "POST",
			data: { ...params }
		});
	}
	// 更新模型净重
	editModelWeight(params: any) {
		return this.request({
			url: "/editModelWeight",
			method: "POST",
			data: { ...params }
		});
	}
	// 3d预览授权
	previewAuth(params: any) {
		return this.request({
			url: "/previewAuth",
			method: "POST",
			data: { ...params }
		});
	}
	// 开业测试
	gift(params: any) {
		return this.request({
			url: "/gift",
			method: "POST",
			data: { ...params }
		});
	}

	// 检查能否进行建模
	canPostMakeModel(params: any) {
		return this.request({
			url: "/canPostMakeModel",
			method: "POST",
			data: { ...params }
		});
	}
	// 门店小票
	shopTicketInfo(params: any) {
		return this.request({
			url: "/shopTicketInfo",
			method: "POST",
			data: { ...params }
		});
	}
	//
	getStudioType(params: any) {
		return this.request({
			url: "/getStudioType",
			method: "POST",
			data: { ...params }
		});
	}
}
export default CustomerP3dLog;
