import { BaseService, Service } from "/@/cool";

const localHttpPrefix = "https://192.168.88.2:8200";

@Service("localPhoto")
class LocalPhoto extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}

		this.permission["page"] = prefix + "page";
	}

	page(data: any) {
		return this.request({
			url: localHttpPrefix + "/photo/page",
			method: "POST",
			data
		});
	}

	switchUploadModel() {
		return this.request({
			url: localHttpPrefix + "/photo/switchUploadModel",
			method: "POST"
		});
	}

	switchUploadStatus() {
		return this.request({
			url: localHttpPrefix + "/photo/switchUploadStatus",
			method: "POST"
		});
	}

	addUploadQueue(data: any) {
		return this.request({
			url: localHttpPrefix + "/photo/addUploadQueue",
			method: "POST",
			data
		});
	}
	photos(data: any) {
		return this.request({
			url: localHttpPrefix + "/photo/photos",
			method: "POST",
			data
		});
	}
	// 审核
	check(data: any) {
		return this.request({
			url: localHttpPrefix + "/photo/check",
			method: "POST",
			data
		});
	}

	shakeCheckImage(data: {}) {
		return this.request({
			url: localHttpPrefix + "/photo/shakeCheckImage",
			method: "POST",
			data: {
				...data
			}
		});
	}
}
export default LocalPhoto;
